import '../style/rider.css';
import { useEffect, useState} from 'react';
import { myFetch } from '../helpers/Api';
import {useLocation, Navigate} from 'react-router-dom';

export default function LessonsHistory(){

    const [lessons, setLessons] = useState([]);
    const location = useLocation();
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {

        const fetchLessonsHistory = () => {
            return location.state?.licenceNum ?
            myFetch('admin_get_rider_lessons_history', {licenceNum: location.state.licenceNum }) :
            myFetch('rider_get_lessons_history')
        }

        fetchLessonsHistory().then(res => res.json(), // Success
                                   res => {if (res.status === 401) setRedirect(true) }) // Error
                                   .then(data => {
            setLessons(location.state?.licenceNum ? data.lessons : data)
        }).catch(() => {})
      }, [location.state?.licenceNum]);

    return <div className='body'>
        <div id='main'>
        <h1>Historique des cours</h1>
        <ul id="lessonsHistoryUL">
        {lessons?.map(x =>
            <li className='lesson' key={x.id}>
                <ul>
                    <li>{new Date(x.time).toLocaleDateString()}</li>
                    <li>{x.isGroup ? 'Cours collectif' : 'Cours particulier'}</li>
                </ul>
            </li>
        )}
        {redirect && <Navigate to={location.state?.licenceNum ? '/admin' : '/cavalier'} />}
        </ul>
        </div>
    </div>
}

